import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import {
  Menu,
  X,
  Leaf,
  Building2,
  FileText,
  User,
  LayoutDashboard,
  Settings,
  Eye,
  EyeOff,
  ChevronDown,
  Target,
  BookOpen
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import logo from './assets/cannvialogo.png';

interface NavItem {
  path: string;
  label: string;
  icon: React.ComponentType<any>;
  activeColor: string;
}

const Navbar: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showFullName, setShowFullName] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setMobileMenuOpen(false);
    setDropdownOpen(false);
  }, [location.pathname]);

  const isActive = (path: string) => location.pathname === path;

  const navItems: NavItem[] = [
    { path: '/strains', label: 'Sorten', icon: Leaf, activeColor: 'green' },
    { path: '/pharmacies', label: 'Apotheken', icon: Building2, activeColor: 'blue' },
    { path: '/strain-finder', label: 'Strain Finder', icon: Target, activeColor: 'purple' }, // Aktiviere lila
  ];

  const handleDashboardClick = () => {
    navigate('/dashboard');
    window.scrollTo(0, 0);
    setMobileMenuOpen(false);
  };

  const handleSettingsClick = () => {
    navigate('/settings');
    window.scrollTo(0, 0);
    setDropdownOpen(false);
    setMobileMenuOpen(false);
  };

  const formatName = (
    firstName?: string,
    lastName?: string,
    role?: string
  ) => {
    if (!firstName || !lastName || !role) return '';
    return !showFullName
      ? role === 'doctor'
        ? `Dr. ${firstName.charAt(0)}. ${lastName.charAt(0)}.`
        : `${firstName.charAt(0)}. ${lastName.charAt(0)}.`
      : role === 'doctor'
        ? `Dr. ${firstName} ${lastName}`
        : `${firstName} ${lastName}`;
  };

  // Mapping-Funktion für Farben
  const getColorClasses = (color: string) => {
    switch(color) {
      case 'green':
        return {
          text: 'text-green-600',
          bg: 'bg-green-600'
        };
      case 'blue':
        return {
          text: 'text-blue-600',
          bg: 'bg-blue-600'
        };
      case 'purple':
        return {
          text: 'text-purple-600',
          bg: 'bg-purple-600'
        };
      // Weitere Farben nach Bedarf hinzufügen
      default:
        return {
          text: 'text-gray-600',
          bg: 'bg-gray-300'
        };
    }
  };

  return (
    <>
      <header className="fixed top-0 left-0 right-0 z-50">
        <nav
          className={`transition-all duration-300 ${
            scrolled 
              ? 'bg-white shadow-lg'
              : 'bg-white'
          }`}
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16 md:h-20 items-center">
              {/* Logo and Desktop Navigation */}
              <div className="hidden md:flex items-center gap-12">
                <Link to="/" className="flex-shrink-0">
                  <div
                    className="transition-transform duration-300 ease-in-out transform hover:scale-105 active:scale-95"
                  >
                    <img
                      src={logo}
                      alt="Canniva Logo"
                      className="h-8 w-auto"
                    />
                  </div>
                </Link>

                {/* Desktop Navigation Links */}
                <div className="flex items-center gap-1">
                  {navItems.map((item) => {
                    const colorClasses = getColorClasses(item.activeColor);
                    return (
                      <Link
                        key={item.path}
                        to={item.path}
                        className="group relative px-4 py-2"
                      >
                        <span
                          className={`inline-flex items-center text-sm font-medium transition-colors duration-300 ${
                            isActive(item.path)
                              ? `${colorClasses.text}`
                              : 'text-gray-600 group-hover:text-gray-900'
                          }`}
                        >
                          <item.icon className="w-4 h-4 mr-2" />
                          {item.label}
                        </span>
                        <span
                          className={`absolute bottom-0 left-0 w-full h-0.5 transform origin-left transition-transform duration-300 ${
                            isActive(item.path)
                              ? `scale-x-100 ${colorClasses.bg}`
                              : 'scale-x-0 bg-gray-300 group-hover:scale-x-100'
                          }`}
                        />
                      </Link>
                    );
                  })}
                </div>
              </div>

              {/* Mobile Title */}
              <div className="md:hidden flex items-center">
                <img src={logo} alt="Canniva Logo" className="h-8 w-auto" />
              </div>

              {/* User Actions */}
              <div className="flex items-center gap-2 md:gap-4">
                {user ? (
                  <>
                    {user.role === 'doctor' ? (
                      <button
                        onClick={handleDashboardClick}
                        className="hidden md:inline-flex items-center px-4 py-2 text-sm font-medium rounded-xl text-white bg-gradient-to-r from-green-600 to-emerald-600 hover:from-green-700 hover:to-emerald-700 transition-all duration-300 transform shadow-lg shadow-green-200/50"
                      >
                        <LayoutDashboard className="w-4 h-4 mr-2" />
                        Dashboard
                      </button>
                    ) : (
                      <Link
                        to="/prescription/request"
                        className="hidden md:inline-flex items-center px-4 py-2 text-sm font-medium rounded-xl text-white bg-gradient-to-r from-green-600 to-emerald-600 hover:from-green-700 hover:to-emerald-700 transition-all duration-300 transform shadow-lg shadow-green-200/50"
                      >
                        <FileText className="w-4 h-4 mr-2" />
                        Patient werden
                      </Link>
                    )}

                    <div className="relative">
                      <button
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                        className="flex items-center gap-2 px-3 md:px-4 py-2 text-sm font-medium rounded-xl bg-gray-100 hover:bg-gray-200 transition-all duration-300"
                      >
                        <User className="w-4 h-4" />
                        <span className="hidden md:inline">{formatName(user.firstName, user.lastName, user.role)}</span>
                        <ChevronDown className={`w-4 h-4 transition-transform duration-300 ${dropdownOpen ? 'rotate-180' : ''}`} />
                      </button>

                      <AnimatePresence>
                        {dropdownOpen && (
                          <div
                            className="absolute right-0 mt-2 w-48 bg-white rounded-xl shadow-lg overflow-hidden z-50"
                          >
                            <button
                              onClick={handleSettingsClick}
                              className="flex items-center w-full px-4 py-3 text-left text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                            >
                              <Settings className="w-4 h-4 mr-2" />
                              Einstellungen
                            </button>
                            <button
                              onClick={() => {
                                logout();
                                setDropdownOpen(false);
                                setMobileMenuOpen(false);
                              }}
                              className="flex items-center w-full px-4 py-3 text-left text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                            >
                              <X className="w-4 h-4 mr-2" />
                              Abmelden
                            </button>
                          </div>
                        )}
                      </AnimatePresence>
                    </div>

                    <button
                      onClick={() => setShowFullName(!showFullName)}
                      className="hidden md:flex p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                    >
                      {showFullName ? (
                        <EyeOff className="w-4 h-4 text-gray-500" />
                      ) : (
                        <Eye className="w-4 h-4 text-gray-500" />
                      )}
                    </button>
                  </>
                ) : (
                  <Link
                    to="/auth/login"
                    className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-xl text-white bg-gradient-to-r from-green-600 to-emerald-600 hover:from-green-700 hover:to-emerald-700 transition-all duration-300 transform shadow-lg shadow-green-200/50"
                  >
                    <User className="w-4 h-4 mr-2" />
                    <span className="hidden md:inline">Anmelden</span>
                  </Link>
                )}

                {/* Mobile Menu Button */}
                <button
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                  className="md:hidden p-2 rounded-lg text-gray-500 hover:text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                >
                  {mobileMenuOpen ? (
                    <X className="w-6 h-6" />
                  ) : (
                    <Menu className="w-6 h-6" />
                  )}
                </button>
              </div>
            </div>
          </div>

          {/* Mobile Menu */}
          <AnimatePresence>
            {mobileMenuOpen && (
              <div
                className="md:hidden bg-white border-t"
              >
                <div className="px-4 py-6 space-y-4">
                  {navItems.map((item, index) => {
                    const colorClasses = getColorClasses(item.activeColor);
                    return (
                      <Link
                        key={item.path}
                        to={item.path}
                        className={`flex items-center px-4 py-3 rounded-xl transition-all duration-300 ${
                          isActive(item.path)
                            ? `${colorClasses.bg} ${colorClasses.text}`
                            : 'text-gray-700 hover:bg-gray-50'
                        }`}
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        <item.icon className="w-5 h-5 mr-3" />
                        {item.label}
                      </Link>
                    );
                  })}

                  {user?.role === 'doctor' && (
                    <button
                      onClick={handleDashboardClick}
                      className="w-full flex items-center px-4 py-3 rounded-xl text-green-600 hover:bg-green-50 transition-all duration-300"
                    >
                      <LayoutDashboard className="w-5 h-5 mr-3" />
                      Dashboard
                    </button>
                  )}

                  {user?.role === 'patient' && (
                    <Link
                      to="/prescription/request"
                      className="w-full flex items-center px-4 py-3 rounded-xl text-green-600 hover:bg-green-50 transition-all duration-300"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <FileText className="w-5 h-5 mr-3" />
                      Rezept beantragen
                    </Link>
                  )}
                </div>
              </div>
            )}
          </AnimatePresence>
        </nav>
      </header>
      {/* Spacer to prevent content from being hidden under fixed navbar */}
      <div className="h-16 md:h-20" />
    </>
  );
};

export default Navbar;
