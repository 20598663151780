import React from 'react';
import { Link } from 'react-router-dom';
import { Instagram, Mail, FileText, HelpCircle, Users, BookOpen, Building2, Briefcase, Truck, CreditCard, Cookie } from 'lucide-react';
import logo from '../components/assets/cannvialogo.png';

const Footer: React.FC = () => {
  return (
    <footer className="bg-white border-t">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col items-center mb-10 text-center">
          <div className="flex items-center mb-4 group">
            <img
              src={logo}
              alt="Canniva Logo"
              className="h-6 w-auto"
            />
          </div>
          <p className="text-xs sm:text-sm text-gray-600 max-w-md">
            Vergleichsportal für medizinisches Cannabis in Deutschland
          </p>
        </div>

        {/* Navigation Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {/* Unternehmen */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wider mb-4">
              Unternehmen
            </h3>
            <ul className="space-y-3">
              <li>
                <Link to="/about" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <Users className="w-4 h-4 mr-2" />
                  Über uns
                </Link>
              </li>
              <li>
                <Link to="/instagram" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <Instagram className="w-4 h-4 mr-2" />
                  Instagram
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <Mail className="w-4 h-4 mr-2" />
                  Kontakt
                </Link>
              </li>
              <li>
                <Link to="/become-partner" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <Building2 className="w-4 h-4 mr-2" />
                  Partner werden
                </Link>
              </li>
              <li>
                <Link to="/jobs" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <Briefcase className="w-4 h-4 mr-2" />
                  Jobs
                </Link>
              </li>
            </ul>
          </div>

          {/* Ressourcen */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wider mb-4">
              Ressourcen
            </h3>
            <ul className="space-y-3">
              <li>
                <Link to="/faq-legalization" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <HelpCircle className="w-4 h-4 mr-2" />
                  FAQs Legalisierung
                </Link>
              </li>
              <li>
                <Link to="/faq-Canniva" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <HelpCircle className="w-4 h-4 mr-2" />
                  FAQs Canniva
                </Link>
              </li>
              <li>
                <Link to="/community-guidelines" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <Users className="w-4 h-4 mr-2" />
                  Community Guidelines
                </Link>
              </li>
            </ul>
          </div>

          {/* Rechtliches */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wider mb-4">
              Rechtliches
            </h3>
            <ul className="space-y-3">
              <li>
                <Link to="/imprint" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <FileText className="w-4 h-4 mr-2" />
                  Impressum
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <FileText className="w-4 h-4 mr-2" />
                  Datenschutz
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <FileText className="w-4 h-4 mr-2" />
                  AGB
                </Link>
              </li>
              <li>
                <Link to="/cookies" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <Cookie className="w-4 h-4 mr-2" />
                  Cookies
                </Link>
              </li>
              <li>
                <Link to="/usage-terms" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <FileText className="w-4 h-4 mr-2" />
                  Nutzungsbedingungen
                </Link>
              </li>
            </ul>
          </div>

          {/* Service */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wider mb-4">
              Service
            </h3>
            <ul className="space-y-3">
              <li>
                <Link to="/shipping" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <Truck className="w-4 h-4 mr-2" />
                  Versand
                </Link>
              </li>
              <li>
                <Link to="/payment-methods" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <CreditCard className="w-4 h-4 mr-2" />
                  Zahlungsmöglichkeiten
                </Link>
              </li>
              <li>
                <Link to="/medical-consultation" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <BookOpen className="w-4 h-4 mr-2" />
                  Medizinische Beratung
                </Link>
              </li>
              <li>
                <Link to="/patient-info" className="text-gray-600 hover:text-green-600 transition-colors duration-300 flex items-center">
                  <BookOpen className="w-4 h-4 mr-2" />
                  Patienteninformationen
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Payment Methods */}
        <div className="border-t border-gray-200 pt-8 mb-8">
          <div className="flex flex-wrap justify-center gap-4 md:gap-6">
            <img src="https://i.imgur.com/IK7GGLe.png" alt="PayPal" className="h-6 md:h-8" />
            <img src="https://i.imgur.com/6tKmheS.png" alt="Apple Pay" className="h-6 md:h-8" />
            <img src="https://i.imgur.com/9ZLPBqW.png" alt="Google Pay" className="h-6 md:h-8" />
            <img src="https://i.imgur.com/fFVHjKl.png" alt="Maestro" className="h-6 md:h-8" />
            <img src="https://i.imgur.com/K9B1crB.png" alt="Visa" className="h-6 md:h-8" />
            <img src="https://i.imgur.com/P2MB8KI.png" alt="Klarna" className="h-6 md:h-8" />
            <img src="https://i.imgur.com/OiZGE1k.png" alt="Sofortüberweisung" className="h-6 md:h-8" />
          </div>
        </div>

        {/* Rechtlicher Hinweis */}
        <div className="border-t border-gray-200 pt-8 mb-8">
          <p className="text-gray-600 text-xs sm:text-sm text-center max-w-3xl mx-auto">
            <strong>Rechtliche und medizinische Hinweise:</strong> Die auf Canniva bereitgestellten Informationen dienen ausschließlich zu Informationszwecken und ersetzen keine individuelle medizinische Beratung. Für medizinische Entscheidungen konsultieren Sie bitte immer Ihren behandelnden Arzt oder qualifiziertes medizinisches Fachpersonal. Alle Angaben zu Produkten und deren Wirkungen basieren auf dokumentierten Erfahrungen und wissenschaftlichen Studien, können jedoch individuell variieren.
          </p>
        </div>

        {/* Copyright */}
        <div className="text-center text-gray-500 text-sm">
          <p>© {new Date().getFullYear()} Canniva. Alle Rechte vorbehalten.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
