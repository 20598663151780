import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import SmoothLayout from './components/layout/SmoothLayout';
import LoadingSpinner from './components/ui/LoadingSpinner';
import { useAuth } from './contexts/AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import CookieConsent from './components/CookieConsent';

// Lazy load pages
const Home = lazy(() => import('./pages/Home'));
const Strains = lazy(() => import('./pages/Strains'));
const StrainFinder = lazy(() => import('./pages/StrainFinder'));
const Pharmacies = lazy(() => import('./pages/Pharmacies'));
const About = lazy(() => import('./pages/company/About'));
const Contact = lazy(() => import('./pages/company/Contact'));
const Instagram = lazy(() => import('./pages/company/Instagram'));
const LegalizationFAQ = lazy(() => import('./pages/resources/LegalizationFAQ'));
const CannivaFAQ = lazy(() => import('./pages/resources/CannivaFAQ'));
const CommunityGuidelines = lazy(() => import('./pages/resources/CommunityGuidelines'));
const Imprint = lazy(() => import('./pages/legal/Imprint'));
const Privacy = lazy(() => import('./pages/legal/Privacy'));
const Terms = lazy(() => import('./pages/legal/Terms'));
const Cookies = lazy(() => import('./pages/legal/Cookies'));
const UsageTerms = lazy(() => import('./pages/legal/UsageTerms'));
const PaymentMethods = lazy(() => import('./pages/service/PaymentMethods'));
const Shipping = lazy(() => import('./pages/service/Shipping'));
const MedicalConsultation = lazy(() => import('./pages/medical/MedicalConsultation'));
const PatientInfo = lazy(() => import('./pages/medical/PatientInfo'));
const Settings = lazy(() => import('./pages/Settings'));
const DoctorDashboard = lazy(() => import('./pages/dashboard/DoctorDashboard'));
const BecomePartner = lazy(() => import('./pages/company/BecomePartner'));
const Jobs = lazy(() => import('./pages/company/Jobs'));
const NotFound = lazy(() => import('./pages/NotFound'));

// Auth Pages
const Login = lazy(() => import('./pages/auth/Login'));
const Register = lazy(() => import('./pages/auth/Register'));
const RequestPrescription = lazy(() => import('./pages/prescription/RequestPrescription'));

// Protected Route Component
const ProtectedRoute = ({ children, allowedRole }: { children: React.ReactNode; allowedRole: string }) => {
  const { user } = useAuth();
  
  if (!user) {
    return <Navigate to="/auth/login" replace />;
  }

  if (allowedRole && user.role !== allowedRole) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

const App = () => {
  const location = useLocation();
  const { user } = useAuth();

  // Redirect from modal routes to dedicated pages
  if (location.state?.modal) {
    const to = location.pathname.replace('/modal', '');
    return <Navigate to={to} replace />;
  }

  return (
    <ErrorBoundary>
      {location.pathname === '/dashboard' ? (
        // Direct route for dashboard without layout
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/dashboard" element={
              <ProtectedRoute allowedRole="doctor">
                <DoctorDashboard />
              </ProtectedRoute>
            } />
          </Routes>
        </Suspense>
      ) : (
        // Routes wrapped with SmoothLayout
        <SmoothLayout>
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path="/" element={<Home />} />
              
              {/* Auth Routes */}
              <Route path="/auth/login" element={
                user ? <Navigate to="/" replace /> : <Login />
              } />
              <Route path="/auth/register" element={
                user ? <Navigate to="/" replace /> : <Register />
              } />
              
              {/* Protected Routes */}
              <Route path="/prescription/request" element={
                <ProtectedRoute allowedRole="patient">
                  <RequestPrescription />
                </ProtectedRoute>
              } />
              <Route path="/settings" element={
                <ProtectedRoute allowedRole="">
                  <Settings />
                </ProtectedRoute>
              } />

              {/* Public Routes */}
              <Route path="/strains" element={<Strains />} />
              <Route path="/strain-finder" element={<StrainFinder />} />
              <Route path="/pharmacies" element={<Pharmacies />} />
              
              {/* Company */}
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/instagram" element={<Instagram />} />
              <Route path="/become-partner" element={<BecomePartner />} />
              <Route path="/jobs" element={<Jobs />} />
              
              {/* Resources */}
              <Route path="/faq-legalization" element={<LegalizationFAQ />} />
              <Route path="/faq-Canniva" element={<CannivaFAQ />} />
              <Route path="/community-guidelines" element={<CommunityGuidelines />} />
              
              {/* Legal */}
              <Route path="/imprint" element={<Imprint />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/cookies" element={<Cookies />} />
              <Route path="/usage-terms" element={<UsageTerms />} />
              
              {/* Service */}
              <Route path="/payment-methods" element={<PaymentMethods />} />
              <Route path="/shipping" element={<Shipping />} />
              
              {/* Medical */}
              <Route path="/medical-consultation" element={<MedicalConsultation />} />
              <Route path="/patient-info" element={<PatientInfo />} />
              
              {/* 404 Page - Must be last */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
          <CookieConsent />
        </SmoothLayout>
      )}
    </ErrorBoundary>
  );
};

export default App;